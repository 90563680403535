// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-workshop-js": () => import("./../src/templates/workshop.js" /* webpackChunkName: "component---src-templates-workshop-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-art-legacy-js": () => import("./../src/pages/artLegacy.js" /* webpackChunkName: "component---src-pages-art-legacy-js" */),
  "component---src-pages-believers-js": () => import("./../src/pages/believers.js" /* webpackChunkName: "component---src-pages-believers-js" */),
  "component---src-pages-bloemen-js": () => import("./../src/pages/bloemen.js" /* webpackChunkName: "component---src-pages-bloemen-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-magazine-js": () => import("./../src/pages/magazine.js" /* webpackChunkName: "component---src-pages-magazine-js" */),
  "component---src-pages-tekeningen-js": () => import("./../src/pages/tekeningen.js" /* webpackChunkName: "component---src-pages-tekeningen-js" */),
  "component---src-pages-video-js": () => import("./../src/pages/video.js" /* webpackChunkName: "component---src-pages-video-js" */),
  "component---src-pages-workshops-js": () => import("./../src/pages/workshops.js" /* webpackChunkName: "component---src-pages-workshops-js" */)
}

